import React from 'react'

import { VBox } from '@/components/VBox';
import { HBox } from '@/components/HBox';

import i18n from '@/i18n'

import { Statistics } from '@/components/Statistics';

import { useSearchParam, useResource } from '@/hooks';
import moment from 'moment';
import { Box, Container, Button, Option, Select } from '@mui/joy';
import { useEffect } from 'react';
import { ToggleButtonBox } from '@/components/ToggleButtonBox';
import { ToggleButton } from '@/components/ToggleButton';
import { CloudDownload } from '@mui/icons-material';
import { downloadStatistics } from '@/actions/aloader';
import { useLabel } from '@/contexts';
import { LoadingPage } from '@/components/LoadingPage';
import { ErrorView } from '@/components/ErrorView';
import { GenericList } from '@/components/GenericList';
import { Typography } from '@mui/material';
import { Img } from '@/components/Img';
import { Link } from 'react-router-dom';
import { ChartLineUp } from '@phosphor-icons/react';
import { VictoryPie } from 'victory';
import { useState } from 'react';
import { Case, SwitchCase } from '@/components/Syntax';

const METRICS = ['streams', 'downloads']

export function TopListContainer({
  start: defaultStart,
  end: defaultEnd
}) {
  const [end, setEnd] = useSearchParam('toDate', defaultEnd ?? moment().subtract(1, 'day').format('YYYY-MM-DD'))
  const [start, setStart] = useSearchParam('fromDate', defaultStart ?? moment().subtract(8, 'day').format('YYYY-MM-DD'))
  
  const [topListViewType, setTopListViewType] = useState('list')

  const { data: top10Recordings, loading: loadingTop10Recordings } = useResource({
    queryKey: ['top', 10, 'recording'],
    path: '/top/10/recordings',
    query: {
      start,
      end,
      ordering: 'top',
      limit: 20
    }
  })

  return (
    <VBox>
         <HBox>
        <Typography sx={{ flex: 1 }} level="h1" component="h1">{i18n.t('top-tracks')}</Typography>
        <ToggleButtonBox> 
          <ToggleButton
            active={topListViewType === 'list'}
            variant="outlined"
            onClick={() => {
              setTopListViewType("list")
            }}
          >
            {i18n.t('list')}
          </ToggleButton>
          <ToggleButton
            active={topListViewType === 'pie'}
            variant="outlined"
            onClick={() => {
              setTopListViewType("pie")
            }}
          >
            {i18n.t('pie-chart')}
          </ToggleButton>
        </ToggleButtonBox>
      </HBox>
      <SwitchCase value={topListViewType}>
        <Case match="list">
          <GenericList
            heading=""
            path={`/top/20/recordings`}
            key={`${start}${end}`}
            queryKey={['top', '20', 'recording', start, end]}
            viewType="list"
            canSearch={false}
            canAdd={false}
            canPaginate={false}
            hasMoreMenu={false}
            showDefaultColumns={false}
            showToolBar={false}
            Icon={ChartLineUp}
            canSelectViewType={false}
            canChangeSortBy={false}
            columns={[
              {
                id: 'number',
                name: i18n.t('number'),
                type: 'number',
                render: ({ column, node, i }) => i 
              },
              {
                id: 'thumbnail_url',
                name: i18n.t('image'),
                type: 'image',
                render: ({ column, node }) => <Img src={node[column.id]} sx={{ width: '30pt', objectFit: 'contain' }} />
              },
              {
                id: 'name',
                name: i18n.t('nane'),
                type: 'text',
                render: ({ column, node }) => <Link to={`/dashboard/recording/${node.id}`}>{node[column.id]}</Link>
              },
              {
                id: 'totals',
                name: i18n.t('streams'),
                type: 'number',
                render: ({ column, node }) => {
                  const value = node[column.id]
                  if (isNaN(value)) {
                    return <Typography>-</Typography>
                  }
                  return (
                    <Typography sx={{ textAlign: 'right' }}>{value?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Typography>
                  )
                } 
              }
            ]}
            query={{
              start,
              end,
              ordering: 'top',
              limit: 20
            }}
          />
        </Case>
        <Case match="pie">
          {loadingTop10Recordings ? (
            <LoadingPage />
          ) : (top10Recordings?.results instanceof Array ? (
            <VictoryPie
              colorScale={'qualitative'}
              data={
                top10Recordings?.results.map(
                  r => ({
                    x: r.name,
                    y: parseInt(r.totals)
                  })
                )
              }
            />
          ) : <ErrorView />)}
        </Case>
      </SwitchCase>
    </VBox>
  )
}

export function StatisticsContainer({
  start: defaultStart,
  end: defaultEnd,
  dataKey: defaultDataKey = 'streams',
  period: defaultPeriod = '7-days',
  recordingIds,
  serviceIds,
  artistIds,
  releaseIds
}) {
  const label = useLabel()
  const [end, setEnd] = useSearchParam('toDate', defaultEnd ?? moment().subtract(1, 'day').format('YYYY-MM-DD'))
  const [start, setStart] = useSearchParam('fromDate', defaultStart ?? moment().subtract(8, 'day').format('YYYY-MM-DD'))
  const [dataKey, setDataKey] = useSearchParam('dataKey', defaultDataKey)

  const [period, setPeriod] = useSearchParam('period', defaultPeriod)

  useEffect(() => {
    switch (period) {
      case "7-days":
        setEnd(moment().subtract(1, 'day').format('YYYY-MM-DD'))
        setStart(moment().subtract(8, 'day').format('YYYY-MM-DD'))
        break;
      case "28-days":
        setEnd(moment().subtract(1, 'day').format('YYYY-MM-DD'))
        setStart(moment().subtract(29, 'day').format('YYYY-MM-DD'))
        break;
    }
  }, [period])
  const { data, isLoading } = useResource({
    queryKey: ['statistics', start, end],
    path: `/statistics`,
    query: {
      start,
      end,
      recordingIds,
      serviceIds,
      artistIds,
      releaseIds
    }
  })

  const handleDownloadStatsClick = event => {
    downloadStatistics({
      query: {
        start,
        end,
        labelId: label.id,
        recordingIds,
        serviceIds,
        artistIds,
        releaseIds
      }
      
    })
  }

  return (
    <VBox>
      <Box sx={{ marginTop: 5, display: 'flex', flexDirection: 'row', alignItems: 'stretch', gap: 1 }}>
        <Typography>{start} - {end}</Typography>
        <Select
          sx={{ transitionDelay: `300ms`, minWidth: 200 }}
          placeholder={i18n.t('period')}
          defaultValue={period}
          onChange={(event, value) => {
            setPeriod(value)
          }}
        >
          <Option value="7-days">{i18n.t('7-days')}</Option>
          <Option value="28-days">{i18n.t('28-days')}</Option>
        </Select>
        <ToggleButtonBox>
          {METRICS.map(metric => (
            <ToggleButton
              active={metric === dataKey}
              variant="outlined"
              onClick={() => {
                setDataKey(metric)
              }}
            >{i18n.t(metric)}</ToggleButton>
          ))}
        </ToggleButtonBox>
        <Button
          startDecorator={<CloudDownload />}
          variant="outlined"
          onClick={handleDownloadStatsClick}
        >
          {i18n.t('download-stats')}
        </Button>
      </Box>
      <HBox>
        {isLoading ?
          <LoadingPage />
         : data ? (
          <Statistics
            key={dataKey}
            dataKey={dataKey}
            start={start}
            end={end}
            data={data}
          />
        ) : <ErrorView />}
      </HBox>
    </VBox>
  )
}

export default function StatisticsPage() {
  return (
    <Container>
      <VBox>
        <h1>{i18n.t('statistics')}</h1>
        <StatisticsContainer />
        <TopListContainer />
      </VBox>
    </Container>
  )
}