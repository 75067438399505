import React from 'react';

import i18n from '@/i18n'

import { useWindowSize } from '@uidotdev/usehooks';
import { Box, Button, Grid, Option, Select, Typography } from '@mui/joy';
import { VBox } from './VBox';
import { SparkLineChart } from '@mui/x-charts/SparkLineChart';
import { useState } from 'react';
import { HighlightedNumber } from './HighlightedNumber';
import { useSearchParam } from '@/hooks';
import { HBox } from './HBox';
import { ToggleButton } from './ToggleButton';
import { LineSeries } from './LineSeries';
import { ToggleButtonBox } from './ToggleButtonBox';
import { BoxArrowDown } from '@phosphor-icons/react';
import { useEffect } from 'react';
import moment from 'moment';

export function Statistics({
    start,
    end,
    period,
    data,
    dataKey
}) {
    const { width, height } = useWindowSize()

    if (!data?.stats) {
        return <Typography>No stats</Typography>
    }
    
    return ( 
        <LineSeries
            width={width}
            height={height * 0.5}
            start={start}
            dataKey={dataKey}
            end={end}
            series={[
                { 
                    x: {
                        field: {
                            id: 'date',
                            name: i18n.t('date')
                        }
                    },
                    y: [
                        {
                            field: {
                                id: dataKey,
                                name: i18n.t(dataKey)
                            }
                        }
                    ],
                    label: i18n.t('gross'),
                    values: data.stats
                }
            ]}
        /> 
    )
}