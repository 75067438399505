import moment from 'moment'

export const EventFlyOut = ({ x, y, height, datum, dx, dy }) => (
  <>
    <defs>
      <filter id="shadow">
        <feDropShadow dx="0" dy="0" floodOpacity={0.5}  />
      </filter>
    </defs>
    <g>
      {/*<line
        x1={x}
        y1={y}
        x2={x}
        y2={(y * 2.5)}
        stroke="black"
      />*/}
      <circle
        cx={x}
        cy={y}
        r={10}
        stroke-width={2}
        style={{ fill: 'var(--primary)', stroke: 'white', filter: 'url(#shadow)' }}
      />
      <rect
        x={x}
        y={y}
        width="200"
        dx={dx}
        dy={dy}
        height="100"
        rx="4.5"
        style={{ fill: datum?.object?.colorScheme?.primary?.dark ?? 'white', filter: 'url(#shadow)' }}
      />
      <image
        x={x + 0}
        y={y + 0}
        width={50}
        height={50}
        href={datum?.object?.imageUrl}
        fill="currentColor"
        style={{ opacity: 1 }}
      />
      <text
        x={x + 60}
        y={y + 20}
        font-size="20"
        font-weight="bold"
        fill={datum?.object?.colorScheme?.primary?.light ?? "currentColor"}
        style={{ opacity: 1 }}
      >
        {datum?.object.name}
      </text> 
      <text
        x={x + 60}
        y={y + 40}
        font-size="10"
        fill={datum?.object?.colorScheme?.primary?.light ?? "currentColor"}
      >
        {`${datum.action.name} · ${moment(datum.date).format('LL')}`}
      </text> 
    </g>
  </>  
);

export const FlyOut = ({ x, y, height, datum, dx, dy }) => (
  <>
    <defs>
      <filter id="shadow">
        <feDropShadow dx="0" dy="0" floodOpacity={0.5}  />
      </filter>
    </defs>
    <g>
      {/*<line
        x1={x}
        y1={y}
        x2={x}
        y2={(y * 2.5)}
        stroke="black"
      />*/}
      <circle
        cx={x}
        cy={y}
        r={10}
        stroke-width={2}
        style={{ fill: 'var(--primary)', stroke: 'white', filter: 'url(#shadow)' }}
      />
      <rect
        x={x - 190}
        y={y - 20}
        width="170"
        dx={dx}
        dy={dy}
        height="100"
        rx="4.5"
        style={{ fill: 'white', filter: 'url(#shadow)' }}
      />
      <text
        x={x - 180}
        y={y + 10}
        font-size="20"
        fill="#868C97"
      >
        {`${moment(datum.date).format('LL')}`}
      </text>
      <text
        x={x - 180}
        y={y + 30}
        font-size="20"
        font-weight="bold"
        fill="#868C97"
      >
        {`${datum.streams} streams`}
      </text> 
    </g>
  </>  
);
