import { useRef, useState, useEffect } from 'react'

import { useNavigate, useSearchParams } from "react-router-dom";

import useSWR from 'swr'

import { authorizedFetch, authorizedFetchResults, restFetcher } from "../client";
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { useQuery as useRestQuery, useInfiniteQuery as useInfiniteRestQuery } from '@tanstack/react-query'

export function useSearchQuery() {
  const query = useSearchParams()
  const navigate = useNavigate()
  const setSearchParams = (value, opts = { replace: false, shallow: false }) => {
    navigate({ query: value }, undefined, opts)
  }
  return [query, setSearchParams]
}

export function useInterval(callback, delay) {
  const savedCallback = useRef();
  const [intervalRef, setIntervalRef] = useState(null);
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  function tick() {
    savedCallback.current();
  }
  useEffect(() => {
    
  }, [delay]);
  const setActive = (active) => {
    if (active) {
      if (delay !== null) {
        const id = setInterval(tick, delay);
        setIntervalRef(id);
        return () => clearInterval(id);
      }
    } else {
      clearInterval(intervalRef);
    }
  }
  return [
    intervalRef,
    setActive
  ]
}

/*
export function useSearchParams() {
  const params = useParams()

  const searchParams = new URLSearchParams(params)
  const setSearchParams = (value, opts = { replace: false, shallow: false }) => {
    if (value instanceof Function) {
      const cb = value
      const searchParams = new URLSearchParams(params)
      const newSearchParams = cb(searchParams)
      const query = { ...params, ...Object.fromEntries(newSearchParams)}
      sh({ query }, undefined, opts)
    } else {
      const query = { ...params, ...value }
      router.push({ query }, undefined, opts);
    }
  }
  return [
    searchParams,
    setSearchParams,
    params
  ]
}
*/
export function useRest(path) {
  return useSWR(path, restFetcher)
}

export function useResource({ queryKey, path, enabled = true, params = null, ...otherProps }) {
  let defaultQuery = {}
  if (otherProps?.query instanceof Object) {
    defaultQuery = otherProps.query
  }

  let query = {}

  for (let k in defaultQuery) {
    if (typeof defaultQuery[k] !== 'undefined') {
      query[k] = defaultQuery[k]
    }
  }

  return useRestQuery({ queryKey, queryFn: () => authorizedFetch("GET", path, params, query), enabled, keepPreviousData: true, ...otherProps })
}

export function useInfiniteResource({ queryKey, query = {}, path, enabled = true, params = null, ...otherProps }) {
  return useInfiniteRestQuery({
    queryKey,
    queryFn: ({ pageParam = 0 }) => authorizedFetchResults("GET", path, params, {
      ...query,
      offset: pageParam * 28,
      limit: 28
    }),
    enabled,
    staleTime: 1 * 60 * 1000, // 1m
    keepPreviousData: true, // Keep data of current page instade of showing loading
    getNextPageParam: (lastPage, allPages) => {
      // 1->2
      return lastPage?.length > 0 ? allPages.length + 1 : undefined;
    },
    ...otherProps
  })
}

export function useLocalStorage(key, defaultValue) {
  let storedValue = localStorage.getItem(key)
  try {
    storedValue = JSON.parse(storedValue) 
  } catch (e) {

  }
  const [value, setValue] = useState(storedValue ?? defaultValue)
  useEffect(() => {
    let saveValue = value
    try {
      localStorage.setItem(key, JSON.stringify(saveValue))
    } catch (e) {
      localStorage.setItem(key, value)
    }
  }, [value])
  return [value, setValue]
}

export function useSearchParam(key, defaultValue = null) {
  const [searchParams, setSearchParams] = useSearchParams()

  let value = searchParams.has(key) ? searchParams.get(key) : defaultValue
  
  if (value === "null") {
    value = null
  }

  const setValue = (value) => {
    if (value) {
      searchParams.set(key, value)
      setSearchParams(searchParams)
    }
  }

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue)
    }
  }, [key, defaultValue])

  return [value, setValue] 
}
