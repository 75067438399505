import React from 'react'

import { Button, styled } from '@mui/joy'

export const ToggleButtonNew = styled(
Button, {
    slot: 'root',
    name: 'ToggleButton'
  }
)(({ theme, props }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'center',
  p: .5,
  color: props.active ? theme.palette.common.black : theme.vars.palette.background.surface ,
  px: 1,
  background: props.active ? theme.vars.palette.background.surface : theme.vars.palette.primary.solidBg, 
  '&:hover': {
    'filter': 'lighten(1)'
  },
  '6:active': {
    'filter': 'darken(1)'
  }
}))


export function ToggleButton({
  active = false,
  children,
  ...props
}) {
  return (
    <Button
      variant={active ? 'plain' : 'solid'}
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        p: .5,
        color: active ? theme.palette.common.black : null,
        px: 1,
        background: active ? theme.vars.palette.background.surface : theme.vars.palette.primary.solidBg, 
        '&:hover': {
          'filter': 'lighten(1)'
        },
        '6:active': {
          'filter': 'darken(1)'
        }
      })}
      {...props }
    >
       {children}
    </Button>
  )
}
