import React from 'react';

import { Card, CardContent, Typography, Skeleton, Box } from '@mui/joy';

import i18n from '@/i18n';
import { Img } from './Img';
import { Disc, Microphone, Smiley } from '@phosphor-icons/react';

export function NodeCard({
  name,
  image_url,
  thumbnail_url,
  number_of_releases = 0,
  loading = false,
  ...props
}) {
  return (
    <Card sx={{ padding: 0}} {...props}>
      {loading ? 
        <Box sx={{ width: '100%', aspectRatio: '1/1', position: 'relative', overflow: 'none' }}>
          <Skeleton  animation="wave" loading={loading} sx={{ width: '100%', aspectRatio: '1/1'}} />
        </Box>
      :
        <Img PlaceholderIcon={Smiley} src={thumbnail_url || image_url} sx={{ aspectRatio: '1/1', objectFit: 'contain' }} />
      }
      <CardContent sx={{ padding: 2, display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
        <Typography sx={{ position: 'relative', minWidth: '100pt', minHeight: '10pt', overflow: 'hidden' }} fontWeight={800} level="h6">
          <Skeleton animation="wave" loading={loading}>
            {loading && 'Loading'}
            {name}
          </Skeleton>
        </Typography>
        <Typography sx={{ position: 'relative', overflow: 'hidden' }}>
          <Skeleton animation="wave" loading={loading}>
            {i18n.t('x-releases', { numberOfReleases: number_of_releases })}
          </Skeleton>
        </Typography>
      </CardContent>
    </Card>
  )
}