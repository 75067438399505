import React from 'react';
import { Alert, Box, Button, Container } from '@mui/joy';
import { TextField } from '@/components/InputField';

import i18n from '@/i18n';
import { useState } from 'react';
import { AudioField } from '@/components/AudioField';

import { SelectRelatedNodes } from '@/components/SelectRelatedNodes';

import { SelectRelatedNode } from '@/components/SelectRelatedNode';
import { Loading } from '@/components/Loading';
import { useParams } from 'react-router-dom';
import { Check, Lock } from '@phosphor-icons/react';
import { db, setDoc, updateDoc, doc } from '@/client';
import { useNavigate } from 'react-router-dom';
import { useResource } from '@/hooks';
import { useEffect } from 'react';
import { ErrorView } from '@/components/ErrorView';
import { useSnackbar } from 'notistack';
import { uploadAudioToRecording } from '@/actions/login';
import { Typography } from '@mui/material';
import { isValidISRC } from '@/utils';
import { FeatureCard } from '@/components/FeatureCard';

export default function EditRecordingPage() {
  const { recordingId } = useParams()
  return (
    <React.Suspense fallback={<Loading />}>
      <EditRecordingView recordingId={recordingId} />
    </React.Suspense>
  )
}

export function RecordingForm({
  values: defaultValues,
  recording,
  saving,
  submitLabel,
  onSubmit = () => console.error('onSubmit not implemented'),
  onValuesChange = () => console.warn('onValuesChange not implemented')
}) {
  const [values, setValues] = useState(defaultValues)

  const [errors, setErrors] = useState([])

  const getFieldErrors = (fieldId) => errors?.filter(i => i.field === fieldId)

  const validateForm = () => {
    let errors = []

    if (!values?.primary_genre) {
      errors.push({
        field: 'primary_genre',
        description: i18n.t('track-must-have-a-genre'),
        type: 'missing'
      })
    }

    if (!values?.name) {
      errors.push({
        field: 'name',
        description: i18n.t('track-must-have-a-name'),
        type: i18n.t('empty')
      })
    }

    if (values?.isrc?.length > 0 && !isValidISRC(values.isrc)) {
      errors.push({
        field: 'isrc',
        description: i18n.t('invalid ISRC code'),
        type: i18n.t('invalid')
      })
    }
    if (values?.recording_contributors?.length > 0) {

    } else {
      errors.push({
        field: 'recording_contributors',
        description: i18n.t('missing-track-contributors'),
        type: 'missing'
      })
    }
    if (values?.recording_artists?.length > 0) {

    } else {
      errors.push({
        field: 'recording_artists',
        description: i18n.t('missing-track-artists'),
        type: 'missing'
      })
    }
    setErrors(errors)
    return errors?.length < 1
  }

  useEffect(() => {
    onValuesChange(values)
  }, [values])

  useEffect(() => {
    setValues({ ...recording })
  }, [recording])

  const handleSubmit = (event) => {
    event.preventDefault()
    if (validateForm()) {
      onSubmit(values)
    }
    return false    
  }

  return (
    <form method="POST" key={recording?.id} onSubmit={handleSubmit}>
      {errors?.map(error => (
        <Alert color="danger">{error.description}</Alert>
      ))}

      {values?.locked && (
        <FeatureCard
          sx={{ marginTop: 2, p: 1 }}
          Icon={Lock}
          name={i18n.t('recording-locked.description')}
          description={i18n.t('recording-locked.heading')}
        /> 
      )}
      <Box>
        <AudioField
          readOnly={values?.locked}
          label={i18n.t('audio')}
          errors={getFieldErrors('recording')}
          audioUrl={recording?.audio_url}
          onChange={(value) => setValues({ ...values, audio: value })}
        />
        <TextField
          readOnly={values?.locked}
          errors={getFieldErrors('name')}
          name="name"
          defaultValue={recording?.name}
          label={i18n.t('title')}
          placeholder={i18n.t("enter-track-title")}
          onChange={(event) => setValues({ ...values, [event.target.name]: event.target.value })}
        />
        <TextField
          readOnly={values?.locked}
          name="version"
          label={i18n.t('version')}
          defaultValue={recording?.version}
          placeholder={i18n.t("enter-track-version")}
          onChange={(event) => setValues({ ...values, [event.target.name]: event.target.value })}
        />
        <Typography sx={{ opacity: 0.5, marginBottom: 1 }}>For example: Remix or Radio edit</Typography>
        <TextField
          readOnly={values?.locked}
          name="isrc"
          errors={getFieldErrors('isrc')}
          label={i18n.t('isrc-optional')}
          pattern="^[A-Z]{2}-?\w{3}-?\d{2}-?\d{5}$"
          defaultValue={recording?.isrc}
          placeholder={i18n.t("not-required-use-only-if-you-are-certain")}
          onChange={(event) => setValues({ ...values, [event.target.name]: event.target.value })}
        />
        <SelectRelatedNode
          readOnly={values?.locked}
          label={i18n.t('genre')}
          errors={getFieldErrors('primary_genre')}
          ownerNodeId={null}
          nodeType="genre"
          ownerNodeType="recording"
          addLabel={"enter-track-genre"}
          placeholder={i18n.t('enter-track-genre')}
          allLabel={i18n.t('all-genres')}
          ownLabel={i18n.t('track-s-genres')}
          node={values?.primary_genre}
          onChange={(primary_genre) => setValues({ ...values, primary_genre })}
        />
        <SelectRelatedNodes
          readOnly={values?.locked}
          label={i18n.t('contributors')}
          errors={getFieldErrors('recording_contributors')}
          ownerNodeId={null}
          nodeType="person"
          placeholder={i18n.t('add-track-contributors')}
          canCreate={true}
          fields={[
            {
              id: 'name',
              type: "text",
              fieldId: 'name',
              label: i18n.t('name')
            }
          ]}
          ownerNodeType="recording"
          addLabel={"add-person"}
          attributes={[
            {
              placeholder: i18n.t('role'),
              id: "roles",
              type: 'belongsToMany',
              multiple: true,
              nodeType: 'roles'
            }
          ]}
          allLabel={i18n.t('all-people')}
          ownLabel={i18n.t('track-s-people')}
          nodes={values?.recording_contributors ?? []}
          onChange={(recording_contributors) => {
            setValues({ ...values, recording_contributors })
          }}
        />
        <SelectRelatedNodes
          readOnly={values?.locked}
          label={i18n.t('payees')}
          ownerNodeId={null}
          nodeType="payee"
          ownerNodeType="recording"
          placeholder={i18n.t('add-track-payees')}
          addLabel={"add-payee"}
          canCreate={true}
          fields={[
            {
              type: "text",
              name: 'name',
              label: i18n.t('name'),
              id: 'name'
            },
            {
              type: 'email',
              label: i18n.t('email'),
              id: 'email'
            }
          ]}
          attributes={[
            {
              type: 'percentage',
              label: i18n.t('percentage'),
              placeholder: i18n.t('0-100'),
              id: 'percentage'
            }
          ]}
          allLabel={i18n.t('all-payees')}
          ownLabel={i18n.t('track-s-payees')}
          nodes={values?.recording_payees ?? []}
          onChange={(recording_payees) => {
            setValues({ ...values, recording_payees })
          }}
        />
        <SelectRelatedNodes
          readOnly={values?.locked}
          errors={getFieldErrors('recording_artists')}
          placeholder={i18n.t('add-track-artists')}
          label={i18n.t('artists')}
          fields={[
            {
              id: 'name',
              type: "text",
              fieldId: 'name',
              label: i18n.t('name')
            }
          ]}
          attributes={[
            /*{
              id: 'primary',
              type: 'bool',
              label: i18n.t('primary')
            }*/
          ]}
          canCreate={true}
          ownerNodeId={null}
          nodeType="artist"
          ownerNodeType="recording"
          addLabel={"add-artist"}
          allLabel={i18n.t('all-artists')}
          ownLabel={i18n.t('track-s-artists')}
          nodes={values?.recording_artists ?? []}
          onChange={(recording_artists) => setValues({ ...values, recording_artists })}
        />
        <Button loading={saving} type="submit" disabled={values?.locked} startDecorator={<Check />} variant="solid">{submitLabel}</Button>
      </Box>
    </form>
  )
}

export function EditRecordingView({
  recordingId
}) {
  const [saving, setSaving] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [values, setValues] = useState(null)

  const { data: recording, loading, error, isError } = useResource({
    queryKey: ['recording', recordingId],
    path: `/recording/${recordingId}`
  })

  const handleSubmit = (values) => {
    try {
      setSaving(true)
      const recording = setDoc(doc(db, 'recordings', recordingId), values).then(() => {
        if (values.audio) {
          uploadAudioToRecording(recordingId, values.audio).then(() => {
            setSaving(false)
            enqueueSnackbar(i18n.t('recording-saved'))
          })
        } else {
          setSaving(false)
          enqueueSnackbar(i18n.t('recording-saved'))
        }
      }).catch(e => {
        console.error(e)
        setSaving(false)
      })
    } catch (e) {
      console.error(e)
      setSaving(false)
    }

    return false
  } 

  useEffect(() => {
    if (recording) {
      setValues({
        ...recording
      })
    }
  }, [recording])

  if (loading) {
    return <Loading />
  }

  console.log(error, isError)

  if (values && recording) {
    return (
      <Container>
        <h1>{i18n.t('edit-track')}</h1>
        <RecordingForm
          saving={saving}
          onSubmit={handleSubmit}
          recording={recording}
          values={values}
          submitLabel={i18n.t('save-track')}
        />
      </Container>
    )
  } else {
    return (
      <ErrorView />
    )
  }
}